@function selfAdapt($px) {
  @return 100vw * ($px / 375);
}

// .banner {
//   background: linear-gradient(132deg, #d4f4ff 0%, #fff5d9 62%, #ffcdbb 100%);
//   padding-top: selfAdapt(70);
//   // padding-bottom: selfAdapt(20);
//   .container {
//     padding: 0 0 0 selfAdapt(20);
//     .left {
//       font-size: selfAdapt(22);
//       font-family: Popins-Bold, Poppins, Popins;
//       font-weight: bold;
//       color: #191919;
//       line-height: selfAdapt(33);
//       width: selfAdapt(190);

//       // word-break: break-all;
//       // text-overflow: ellipsis;
//       // overflow: hidden;
//       // display: -webkit-box;
//       // /** 对象作为伸缩盒子模型显示 **/
//       // -webkit-box-orient: vertical;
//       // /** 设置或检索伸缩盒对象的子元素的排列方式 **/
//       // -webkit-line-clamp: 3;
//       /** 显示的行数 **/
//       // height: 70px;
//       // min-width: selfAdapt();
//     }
//     .pic {
//       width: selfAdapt(166);
//     }
//   }
// }
.banner {
  height: 200px;
  width: 100%;
  background: linear-gradient(132deg, #d4f4ff 0%, #fff5d9 62%, #ffcdbb 100%);

  .container {
    height: 200px;
    width: 100%;
  }

  .custom-dot {
    color: black;
    bottom: 5px;
    margin-bottom: 0;

    li {
      width: 6px !important;
      height: 6px !important;
      border-radius: 3px !important;
      background: rgba(0, 0, 0, 0.2);

      button {
        width: 6px !important;
        height: 6px !important;
        opacity: 0;
        border-radius: 3px !important;
      }
    }
  }
}
